<!--
* @Author: DY
* @Date: 2020-05-27 14:52:13
* @Description: 框架内通用列表
-->
<template>
    <div class="currencyTable table-bg" v-loading="loading">
        <div class="table-header">
            <div class="el_left">
                <slot name="queryCriteria"></slot>
                <slot name="buttons"></slot>
            </div>
            <div class="el_right">
                <slot :data="tableSelectData">
                    <div class="el_left conditionQuery" v-if="isConditionQuery">
                        <el-input placeholder="请输入搜索内容..." v-model="generalQueryText" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="generalQuery"></el-button>
                        </el-input>
                    </div>
                </slot>
                <div class="el_left setUp">
                    <el-button v-popover:popover>
                        <i class="iconfont iconshezhi"></i>
                    </el-button>
                    <el-popover
                        ref="popover"
                        placement="bottom-start"
                        title="自定义列表项"
                        width="540"
                        trigger="click"
                        v-model="isPopover"
                    >
                        <div class="tablePopover">
                            <div class="popoverContent">
                                <el-checkbox-group v-model="selectListItem">
                                    <template v-for="(item, index) in listDisplayItems">
                                        <el-checkbox :key="index" :label="item.field">
                                            {{ item.title }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                            <div class="el_right">
                                <el-button size="mini" @click="isPopover = false">
                                    取消
                                </el-button>
                                <el-button size="mini" type="primary" @click="confirmListItem">
                                    确定
                                </el-button>
                            </div>
                        </div>
                    </el-popover>
                </div>
            </div>
        </div>
        <el-table
            border
            stripe
            :data="tableList"
            ref="multipleTable"
            @row-click="selateTable"
            :highlight-current-row="true"
            @selection-change="handleSelectionChange"
            @sort-change="sortChange"
        >
            <el-table-column
                key="select"
                type="selection"
                width="55"
                v-if="showCheckBox"
            >
            </el-table-column>
            <el-table-column
                label="选择"
                width="55"
                v-if="showRadio"
            >
                <template slot-scope="scope">
                    <el-radio
                        v-model="radio"
                        :label="scope.row"
                    >
                        <i></i>
                    </el-radio>
                </template>
            </el-table-column>
            <el-table-column
                label="序号"
                type="index"
                width="70"
                v-if="isShowNo"
                :index="indexMethod"
            >
            </el-table-column>
            <template v-for="(item,index) in tableHeader">
                <el-table-column
                    :key="index"
                    :prop="item.field"
                    :label="item.title"
                    :width="item.width"
                    show-overflow-tooltip
                    :sortable="item.sortable ? item.sortable : false"
                >
                    <template slot-scope="scope">
                        <!--表格内状态列-->
                        <div
                            v-if="item.state"
                            :style="item.style"
                        >
                            <template v-for="(state,k) in item.state">
                                <div :key="k" v-if="state.val == scope.row[item.field]">
                                    {{ state.name }}
                                </div>
                            </template>
                        </div>
                        <!--表格内link列-->
                        <div
                            v-else-if="item.link"
                            :style="item.style"
                        >
                            <el-link
                                :type="item.link.linkType"
                                @click.stop="handleLinkClick(item.link, scope.row)"
                            >
                                {{ scope.row[item.field] }}
                            </el-link>
                        </div>
                        <!--表格内操作列，可点击，传入方法类型，用clickType区分按钮点击事件。-->
                        <div
                            v-else-if="item.btns"
                            :style="item.style"
                            :class="{ nowrap: item.isAutoWrap }"
                        >
                            <template v-for="(btn, i) in item.btns">
                                <el-button
                                    size="mini"
                                    :class="btn.btnClass"
                                    :key="i"
                                    :type="btn.btnType"
                                    plain
                                    @click.stop="handleBtnClick(btn, scope.row)"
                                >
                                    {{ btn.name }}
                                </el-button>
                            </template>
                        </div>
                        <!--表格内可编辑列-->
                        <div
                            v-else-if="item.isEdit"
                            :style="item.style"
                            class="editList"
                            @click.stop
                        >
                            <template v-if="item.editType == 'text'">
                                <input
                                    type="text"
                                    class="table_input"
                                    :readonly="item.isSee"
                                    :placeholder="item.title"
                                    v-model="scope.row[item.field]"
                                    @change="valueModify(item, scope.row, scope.$index)"
                                >
                            </template>
                            <template v-if="item.editType == 'item'">
                                <el-date-picker
                                    v-model="scope.row[item.field]"
                                    type="datetime"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </template>
                            <template v-if="item.editType == 'checkbox'">
                                <el-checkbox v-model="scope.row[item.field]">
                                    {{ item.label||'' }}
                                </el-checkbox>
                            </template>
                        </div>
                        <el-tooltip
                            v-else-if="item.tooltip"
                            class="item"
                            effect="dark"
                            placement="top"
                            :content="scope.row[item.field] + ''"
                        >
                            <div
                                :style="item.style"
                                :class="{ nowrap: item.isAutoWrap }"
                            >
                                {{ scope.row[item.field] }}
                            </div>
                        </el-tooltip>
                        <div
                            v-else
                            :style="item.style"
                        >
                            {{ scope.row[item.field] }}
                        </div>
                    </template>
                </el-table-column>
            </template>
        </el-table>
        <div class="table-page" v-if="isShowTablePage">
            <div class="el_right">
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[20,40,60,80,100]"
                    :page-size="limit"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/common/eventBus.ts';

export default {
    name: 'currency-table',
    components: {},
    props: {
        tableData: {
            type: [Array, Object, String, Number],
        },
    },
    data() {
        return {
            loading: false,
            // 查询条件
            generalQueryText: '',
            // 是否显示普通查询
            isConditionQuery: true,
            // 自定义列表项数据
            listDisplayItems: [],
            // 已选中显示项
            selectListItem: [],
            // 列表项选择弹出层状态
            isPopover: false,
            // 列表头部数据
            tableHeader: [],
            // 列表数据
            tableList: [],
            // 数据总条数
            total: 0,
            // 当前页码
            page: 1,
            // 每页显示条目个数
            limit: 20,
            // 是否显示复选框
            showCheckBox: false,
            // 是否显示单选框
            showRadio: false,
            // 是否显示序号列
            isShowNo: false,
            // 单选数据
            radio: {},
            // 列表选择数据集合
            tableSelectData: [],
            // 是否显示分页
            isShowTablePage: true,
        };
    },
    watch: {},
    computed: {},
    created() {
        if (this.tableData) {
            if (this.tableData.generalQuery === false) {
                this.isConditionQuery = false;
            }
            if (this.tableData.showCheckBox) {
                this.showCheckBox = true;
            }
            if (this.tableData.showRadio) {
                this.showRadio = true;
            }
            if (this.tableData.isShowNo) {
                this.isShowNo = true;
            }
            if (this.tableData.isShowTablePage === false) {
                this.isShowTablePage = false;
            }
            if (this.tableData.headerData) {
                this.listDisplayItems = this.tableData.headerData;
                this.selectListItem = this.tableData.headerData.map(item => item.field);
                this.tableHeader = this.tableData.headerData;
            }
            if (this.tableData.requestUrl) {
                // 获取列表数据
                this.getTableData();
            }
            if (this.tableData.listData) {
                this.tableList = this.tableData.listData;
            }
        }
    },
    mounted() {
        // 添加事件总线eventBus/table刷新方法
        eventBus.$on('updateTable', () => {
            this.getTableData();
        });
    },
    methods: {
        // 计算列表序号
        indexMethod(index) {
            return (this.page - 1) * this.limit + index + 1;
        },
        // 点击行选中
        selateTable(row) {
            this.tableSelectData = [];
            if (this.showCheckBox) {
                this.$refs.multipleTable.toggleRowSelection(row);
                this.tableSelectData = this.$refs.multipleTable.selection;
            } else if (this.showRadio) {
                this.radio = row;
                this.tableSelectData.push(row);
            }
        },
        // 多选选中数据集合
        handleSelectionChange() {
            this.tableSelectData = this.$refs.multipleTable.selection;
        },
        // 确认选择列表显示项
        confirmListItem() {
            const newColumns = [];
            this.listDisplayItems.forEach(k => {
                this.selectListItem.forEach(item => {
                    if (item === k.field) {
                        newColumns.push(k);
                    }
                });
            });
            this.tableHeader = newColumns;
            this.isPopover = false;
        },
        // 列表上link点击方法
        handleLinkClick(link, row) {
            this.$emit(link.method, row);
        },
        // 列表上按钮点击方法
        handleBtnClick(btn, row) {
            this.$emit(btn.method, row);
        },
        // 列表上可编辑列值发生变化
        valueModify(item, row, index) {
            if (item.changeName) {
                this.$emit(item.changeName, row, index);
            }
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getTableData();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getTableData();
        },
        // 查询
        generalQuery() {
            this.getTableData();
        },
        // 获取列表数据
        getTableData() {
            this.loading = true;
            if ((this.tableData.requestMethod || '') === 'post') {
                this.request_Post();
            } else {
                this.request_Get();
            }
        },
        // get请求
        request_Get() {
            let requestUrl = `/interfaceApi${this.tableData.requestUrl}?pageindex=${this.page}&pagesize=${this.limit}`;
            if (this.generalQueryText !== '') {
                requestUrl = requestUrl + `&search=${this.generalQueryText}`;
            }
            if (this.tableData.requestParameter !== '') {
                requestUrl = requestUrl + this.tableData.requestParameter;
            }
            this.$axios
                .get(requestUrl)
                .then(res => {
                    this.$set(this, 'tableList', res.rows);
                    // 数据总条数
                    this.total = res.total;
                    // 强制刷新
                    this.$forceUpdate();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // post请求
        request_Post() {
            let requestUrl = `/interfaceApi${this.tableData.requestUrl}`;
            let requestParameters = {};
            if (typeof this.tableData.requestParameter === 'string') {
                requestUrl = `${requestUrl}?pageindex=${this.page}&pagesize=${this.limit}${this.tableData.requestParameter}`;
                if (this.generalQueryText !== '') {
                    requestUrl = requestUrl + `&search=${this.generalQueryText}`;
                }
            } else if (typeof this.tableData.requestParameter === 'object') {
                requestParameters = this.tableData.requestParameter;
                requestParameters.pageindex = this.page;
                requestParameters.pagesize = this.limit;
                if (this.generalQueryText !== '') {
                    requestParameters.search = this.generalQueryText;
                }
            }
            this.$axios
                .post(requestUrl, requestParameters)
                .then(res => {
                    this.$set(this, 'tableList', res.rows);
                    // 数据总条数
                    this.total = res.total;
                    // 强制刷新
                    this.$forceUpdate();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 服务端通用排序
        sortChange({ prop, order }) {
            this.$emit('sortChange', { prop, order });
        },
    },
    beforeDestroy() {
        // 清除事件总线eventBus
        eventBus.$off('updateTable');
    },
};
</script>

<style lang="stylus">
.currencyTable
    height 100%
    padding 0.14rem
    background #fff
    .table-header
        height 0.51rem
        .btns
            float left
            .el-button
                color #fff
                background #2978ff
                padding 0 0.2rem
                border none
                line-height 0.36rem
        .conditionQuery
            width 3.6rem
            .el-input-group__append
                background #2978ff
                border 1px solid #2978ff
                .el-button
                    color #fff
        .setUp
            .el-button
                width 0.36rem
                height 0.36rem
                padding 0
                color #fff
                font-size 0.16rem
                background #2978ff
                margin-left 0.1rem
    .el-table
        height calc(100% - 0.95rem)
        th,td
            padding 0
            .cell
                padding 0
        .editList
            >div
                vertical-align top
            .table_input
                width 100%
                height 0.34rem
                line-height 0.34rem
                text-indent 0.14rem
                border 1px solid #DCDFE6
                border-radius 2px
            .el-date-editor
                width 100% !important
</style>
