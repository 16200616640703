<!--
* @Author: DY
* @Date: 2020年12月18日16:34:19
* @Description: 弹出列表选择添加

* 调用方式
* const addTableData = {
*     showCheckBox: true,                          是否显示多选列
*     showRadio: true,                             是否显示单选列
*     isShowNo: true,                              是否显示序号列
*     headerData: [],                              列表表头数据
*     requestMethod: 'post',                       请求方式，默认get
*     requestUrl: ``,                              接口地址
*     requestParameter: '',                        post请求附加参数
* };
* this.$toast({
*     title: true,
*     text: '添加退砼',
*     type: 'eject',
*     width: '15rem',
*     height: '75%',
*     viewPosition: 'components',
*     t_url: 'table/addTable',
*     extr: {
*         tips: '添加退砼',                         没有选中数据提示信息（必传）
*         tableData: this.addTableData,            初始化列表数据（必传）
*         sureCallBack: this.addToCallBack,        确认选择回调（必传）
*     },
* });
-->
<template>
    <div class="addTables">
        <currencyTable :table-data="tableData" ref="addTables">
            <div slot="buttons" class="btns">
                <el-button size="mini" @click="confirmSelection">
                    确认选择
                </el-button>
            </div>
        </currencyTable>
    </div>
</template>

<script>
import currencyTable from '@/components/table/index.vue';


export default {
    name: 'add-tables',
    components: {
        currencyTable,
    },
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            tableData: {},
        };
    },
    watch: {},
    computed: {},
    created() {
        if (this.extr && this.extr.tableData) {
            this.tableData = this.extr.tableData;
        }
    },
    mounted() {},
    methods: {
        // 确认选择
        confirmSelection() {
            const selectData = this.$refs.addTables.tableSelectData;
            if (selectData.length < 1) {
                this.$message({
                    message: `请先选择${this.extr.tips}！`,
                    type: 'warning',
                });
            } else {
                this.extr.sureCallBack(selectData);
                this.$parent.hide();
            }
        },
    },
};
</script>

<style scoped lang="stylus">
.addTables
    height 100%
</style>
